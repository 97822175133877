import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

const Download = styled.a`
  visibility: hidden;
`

const Index = ({ downloadURL }) => {
  // const ref = React.useRef(null)

  // React.useEffect(() => {
  //   ref?.current?.click()
  // }, [])

  return (
    <Wrapper>
      {/* <Download href={downloadURL} download={fileName} ref={ref} /> */}
      <iframe
        src={downloadURL}
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
    </Wrapper>
  )
}

export default Index